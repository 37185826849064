

body {
    background: var(--backgroundColor);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    line-height: 1;
    color: var(--textColor);
  }
  p {
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    font-family: var(--headingFont);
    font-weight: 400;
    line-height: 1;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
  }
  
  h1 {
    font-size: 3.052rem;
  }
  
  h2 {
    font-size: 2.441rem;
  }
  
  h3 {
    font-size: 1.953rem;
  }
  
  h4 {
    font-size: 1.563rem;
  }
  
  h5 {
    font-size: 1.25rem;
  }
  
  .text {
    margin-bottom: 1.5rem;
    max-width: 40em;
  }
  
  small,
  .text-small {
    font-size: var(--small-text);
  }
  
  a {
    text-decoration: none;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  .img {
    width: 100%;
    display: block;
    object-fit: cover;
  }
  /* buttons */
  
  .btn {
    cursor: pointer;
    color: var(--white);
    background: var(--primary-500);
    border: transparent;
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
    padding: 0.375rem 0.75rem;
    box-shadow: var(--shadow-1);
    transition: var(--transition);
    text-transform: capitalize;
  }
  .btn:hover {
    background: var(--primary-700);
    box-shadow: var(--shadow-3);
  }
  .btn-hipster {
    color: var(--primary-500);
    background: var(--primary-200);
  }
  .btn-hipster:hover {
    color: var(--primary-200);
    background: var(--primary-700);
  }
  .btn-block {
    width: 100%;
  }
  
  /* alerts */
  .alert {
    padding: 0.375rem 0.75rem;
    margin-bottom: 1rem;
    border-color: transparent;
    border-radius: var(--borderRadius);
  }
  
  .alert-danger {
    color: var(--red-dark);
    background: var(--red-light);
  }
  .alert-success {
    color: var(--green-dark);
    background: var(--green-light);
  }
  /* form */
  
  .form {
    width: 90vw;
    max-width: var(--fixed-width);
    background: var(--white);
    border-radius: var(--borderRadius);
    box-shadow: var(--shadow-2);
    padding: 2rem 2.5rem;
    margin: 3rem auto;
  }
  .form-label {
    display: block;
    font-size: var(--small-text);
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
  }
  .form-input,
  .form-textarea {
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: var(--borderRadius);
    background: var(--backgroundColor);
    border: 1px solid var(--grey-200);
  }
  
  .form-row {
    margin-bottom: 1rem;
  }
  
  .form-textarea {
    height: 7rem;
  }
  ::placeholder {
    font-family: inherit;
    color: var(--grey-400);
  }
  .form-alert {
    color: var(--red-dark);
    letter-spacing: var(--letterSpacing);
    text-transform: capitalize;
  }
  /* alert */
  
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  
  .loading {
    width: 6rem;
    height: 6rem;
    border: 5px solid var(--grey-400);
    border-radius: 50%;
    border-top-color: var(--primary-500);
    animation: spinner 0.6s linear infinite;
    margin: 0 auto;
  }
  
  /* title */
  
  .title {
    text-align: center;
  }
  
  .title-underline {
    background: var(--primary-500);
    width: 7rem;
    height: 0.25rem;
    margin: 0 auto;
    margin-top: 1rem;
  }
  /* ============= PROJECT CSS =============== */
  
  .menu {
    padding: 5rem 0;
  }
  
  .btn-container {
    margin: 2rem 0 4rem 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
  }
  
  .menu-item {
    background: var(--white);
    border-radius: var(--borderRadius);
  }
  .menu-item .img {
    height: 15rem;
    border-top-right-radius: var(--borderRadius);
    border-top-left-radius: var(--borderRadius);
  }
  .item-info {
    padding: 1.5rem;
  }
  .item-info header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.25rem;
  }
  
  .item-info h5 {
    font-weight: 500;
  }
  .item-price {
    background: var(--primary-500);
    color: var(--white);
    padding: 0.25rem 0.5rem;
    letter-spacing: var(--letterSpacing);
    border-radius: var(--borderRadius);
  }
  
  .item-text {
    line-height: 2;
    color: var(--grey-500);
  }
  
  .section-center {
    justify-items: center;
  }
  .menu-item {
    max-width: 25rem;
  }
  
  @media screen and (min-width: 992px) {
    .section-center {
      grid-template-columns: 1fr 1fr;
      align-items: start;
    }
  }
  @media screen and (min-width: 1170px) {
    .section-center {
      width: 95vw;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  